.row-striped:nth-child(even) {background: #EEE}
.row-striped:nth-child(odd) {background: #FFF}
.row-striped:hover {background: rgb(200, 230, 250)}

.listRow:nth-child(even) {background: #EEE}
.listRow:nth-child(odd) {background: #FFF}
.listRow:hover {background: rgb(200, 230, 250)}

.overflowY-scroll {overflow-y: scroll;}

.scrollX {overflow-x: scroll;overflow-y: hidden;}
.scrollY {overflow-y: scroll;overflow-x: hidden;}
.scrollXY {overflow-x: scroll;overflow-y: scroll;}

.bg-selected {background: rgb(130, 180, 220)!important}

.bg-capeb, .bg-CAPEB  {background: #e74955!important}
.bg-cnatp, .bg-CNATP {background: #a5d16c!important}
.bg-cnams, .bg-CNAMS {background: #32c2cd!important}
.bg-cgad,  .bg-CGAD {background: #fcb322!important}
.bg-unapl, .bg-UNAPL {background: #777!important}
.bg-micro, .bg-MICRO {background: #b0c4de!important}

.p-pointer {cursor: pointer;}
.p-default {cursor: default;}